import React from 'react'
import { Link, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PortableText } from "@portabletext/react"
import Serializers from '../components/serializers'
import styled from "@emotion/styled"
import Layout from '../components/layout'

const _ = require("lodash")

const Grid = styled.ul`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 10px;
a {
    text-decoration: none;
    transition: all 0.3s ease;
    h3 {
        padding-top: 20px;
    }
    p {
        color: rgb(122,122,122);
        width: 100%;
    }
    &:hover {
        opacity: .66;
    }
}
`
export const pageQuery = graphql`
query PageQuery ($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      projects {
        id
        title
        _rawDescription(resolveReferences: {maxDepth: 5})
        image {
              image {
                asset {
                  gatsbyImageData(aspectRatio: 1.5)
                }
              }
            }
      }
  
    }
  }
`

const PageTemplate = (props) => {
    const { data } = props
    const page = data && data.page
    return (
        <Layout>
            <Grid>
            {page.projects.map( item => {
                return (
                <li key={item.id}>
                    <Link to={`/album/${_.kebabCase(item.title)}`}>
                    <GatsbyImage image={item.image[0].image.asset.gatsbyImageData} alt={item.image[0].image.caption ? item.image[0].image.caption : 'image'}/>
                    <h3>{item.title}</h3>
                    {item._rawDescription &&
                      <PortableText
                      value={item._rawDescription}
                      components={Serializers}
                      />
                    }
                </Link>
                </li>
                )
            })}
            </Grid>
        </Layout>
    )
}

export default PageTemplate